<template>
  <div>
    <Header />
    <template v-for="block,index in blocks" >
      <Block
        :key="index"
        :block="block"
      />
    </template>
    <Footer with-links/>
  </div>
</template>

<script>
import Block from '../components/block.vue'
import Footer from '../layout/footer'
import Header from '../layout/header'

const base_url = process.env.VUE_APP_BASE_URL;
const axios = require('axios');
export default {
    name: "Detail",
    components: {
        Block,
        Footer,
        Header,
    },
    data(){
        return{
            blocks : [],
        }
    },
    watch: {
        '$locale': function(newVal) {
            this.loadData(this.$route.params.p,newVal);
        }
    },
    created() {
        this.loadData(this.$route.query.p,this.$locale);
    },
    methods :{
        loadData(page,lang){
            axios.get(base_url + 'page/' +page +'/'+lang).then((response) => {
                this.blocks = response.data.data;
            }).catch((error) => {
                console.log(error);
                this.$router.push('/404')
            });
        },
    }
}
</script>