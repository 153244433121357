<template>
  <div class="contact-form">
    <h3>{{$t('contact')}}</h3>
    <form method="post" @submit="submitForm">
      <div>
        <input type="text" v-model="form.name" required :placeholder="placeholder[0]" name="name" />
      </div>
      <div>
        <input type="email" v-model="form.email" required :placeholder="placeholder[1]" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" name="email" />
      </div>
      <div>
        <input type="tel" required v-model="form.phone" name="phone" pattern="[0-9]+" :placeholder="placeholder[2]" />
      </div>
      <div>
        <input type="text" required v-model="form.subject" name="subject" :placeholder="placeholder[3]" />
      </div>
      <div>
        <textarea name="message" v-model="form.message" required :placeholder="placeholder[4]" rows="5"></textarea>
      </div>
      <button type="submit">{{$t('send')}}</button>

    </form>
  </div>
</template>

<script>
const base_url = process.env.VUE_APP_BASE_URL;
const axios = require('axios');

export default {
    name: "contactForm",
    data() {
        return {
            placeholder: [this.$t('nom'),this.$t('email'),this.$t('phone'),this.$t('sujet_message'),this.$t('message'),this.$t('send')],
            form :{
                name: '',
                email: '',
                phone: '',
                subject: '',
                message: ''

            },
        }
    },
    watch: {
        '$locale': function() {
            this.placeholder = [this.$t('name'),this.$t('email'),this.$t('phone'),this.$t('sujet_message'),this.$t('message'),this.$t('send')]
        }
    },
    methods: {
        submitForm(e){
            e.preventDefault();
            const formdata = new FormData();
            const content = `
      <html>
            <h2>New Message From Website</h2>
      <h4>Name : ${this.form.name}</h4>
      <h4>email : ${this.form.email}</h4>
      <h4>phone : ${this.form.phone}</h4>
      <h4>subject : ${this.form.subject}</h4>
      <h4>messsage : ${this.form.message}</h4>
            </html>`;
            formdata.append("content", content);
            axios.post(base_url + 'mailer',formdata).then(() => {
                this.form = {
                    name: '',
                    email: '',
                    phone: '',
                    subject: '',
                    message: ''
                };
                alert(this.$t('message_envoye_avec_succes'));
            }).catch((error) => {
                console.log(error);
                alert('Whoops...Something went wrong !! please try again')
            });
        },
    }


}
</script>

<style lang="scss" scoped>
.contact-form{
  width: 100%;
  max-width: 300px;
  font-family: "Wremena-regular";
  @media screen and (max-width: 575px){
    max-width: 100%;
  }
  h3{
    color: #ffffff;
    font-size: 2rem;
    margin-bottom: .5rem;
  }
  form{
    width: 100%;

    div{
      position: relative;
      margin-bottom: 1rem;

      input,textarea{
        padding: .5rem 1rem;
        width: 100%;
        border: 1px solid $color2;
        color: #3E464A;
        background-color: #ffffff;
        outline: none;

      }
    }
    button{
      display: block;
      width: 100%;
      background-color: $color2;
      color: #ffffff;
      text-align: center;
      padding: .5rem 1rem;
      outline: none;
      border: none;
      font-size: 1.1rem;
      &:hover{
        background-color: $color6;
      }
    }
  }
}
</style>