<template>
  <div>
    <Header/>
      <Block
        v-for="block,index in blocks"
        :key="index"
        :block="block"
        class="politique"
      />
    <Footer with-links/>
  </div>
</template>

<script>
import Block from '../components/block.vue'
import Footer from '../layout/footer'
import Header from '../layout/header'

const base_url = process.env.VUE_APP_BASE_URL;
const axios = require('axios');
export default {
    name: "Confidentiality_politique",
    components: {
        Block,
        Footer,
        Header,
    },
    data(){
        return{
            blocks : [],
        }
    },
    watch: {
        '$locale': function(newVal) {
            this.loadData(newVal);
        }
    },
    created() {
        this.loadData(this.$locale);
    },
    methods :{
        loadData(lang){
            axios.get(base_url + 'page/politique_de_confidentialite/'+lang).then((response) => {
                this.blocks = response.data.data;
            }).catch((error) => {
                console.log(error);
            });
        },
    }
}
</script>

<style lang="scss">
  .Block.politique{
    .inner-content{
      align-items: flex-start;
      padding-bottom: 50px;
      p{
        max-width: unset;
        width: 100%;
        text-align: left;
        color: #0b0f12d4;
        margin-bottom: 25px;
        strong{
          font-size: 1.2rem;
        }

      }
      a, p a{
        display: inline-block;
        margin: 0 0 20px 0;
        padding: 5px 10px;
        min-width: unset;
      }
    }
  }
</style>