<template>
  <div>
    <Header />

    <Block
      v-for="block,blockIdx in blocks"
      :key="blockIdx"
      :block="block"
    />
    <Salles :data="salles" />
    <Equipments :data="equipments" />

    <DataCarousel v-if="categories.length > 0" :data="categories" />
    <Footer with-links/>
  </div>
</template>

<script>
import Block from '../components/block.vue'
import Footer from '../layout/footer'
import Header from '../layout/header'
import Equipments from "../components/equipements";
import Salles from "../components/salles";
import DataCarousel from "../components/dataCarousel";
const base_url = process.env.VUE_APP_BASE_URL;
const axios = require('axios');
export default {
    name: "EventsPage",
    components: {
        Equipments,
        Block,
        Footer,
        Header,
        DataCarousel,
        Salles
    },
    data(){
        return{
            blocks : [],
            equipments : [],
            categories : [],
            salles : [],
        }
    },
    watch: {
        '$locale': function(newVal) {
            this.loadData(newVal);
        }
    },
    created() {
        this.loadData(this.$locale);
        this.loadEquipData();
        this.loadEquipCategoryData();
        this.loadSallesData();

    },
    methods :{
      loadData(lang){
          axios.get(base_url + 'page/events/'+lang).then((response) => {
              this.blocks = response.data.data;
          }).catch((error) => {
              console.log(error);
          });
      },
      async loadEquipData(){
          let res = await axios.get(base_url + 'event-equipments');
          this.equipments = res.data.eventEquipments;
      },
      async loadEquipCategoryData(){
          let res = await axios.get(base_url + 'event-categories');
          this.categories = res.data.eventCategories;
      },
      async loadSallesData(){
          let res = await axios.get(base_url + 'conf-rooms-details');
          this.salles = res.data.rooms;
      }
    }
}
</script>

<style lang="scss">
  .events-carousel {
    padding-left: 10vw;
    margin-bottom: 10vw;
    position: relative;
    .item{
      width: 35vw;
      cursor: pointer;
      .img-ctn{
        width: 100%;
        height: 360px;
        position: relative;
        overflow: hidden;
        img{
          position: absolute;
          top: 50%;
          left: 50%;
          width: auto;
          height: auto;
          transform: translate(-50%,-50%);
          min-width: 100%;
          min-height: 100%;
        }
      }
      span{
        display: block;
        margin: 60px 0;
        color: $color1;
        font-family: 'Hunter';
        font-size: 1.5rem;
        text-transform: uppercase;
        letter-spacing: 4px;
        text-align: center;
        opacity: .5;
      }
      p{
        color: $color1;
        text-align: center;
        font-family: 'Lucida-grande';
        padding: 0 80px;
        font-size: .9rem;
        opacity: .5;
        line-height: 1.7;
        letter-spacing: 1px;
        &::first-letter{
          text-transform: uppercase;
        }
      }
    }
    .owl-nav{
      position: absolute;
      left: 0;
      top: 30%;
      display: flex !important;
      flex-direction: column-reverse;
      transform: translate(-200%,-50%);
      >div{
        font-size: 0 !important;
        padding: 0 !important;
        background-color: transparent !important;
        display: block !important;
        width: 3vw;
        height: 2vw;
        background-position: 50% 50% !important;
        background-size: contain !important;
        background-repeat: no-repeat !important;
        background-image: url(../assets/images/svg/events/Right_Arrow.svg) !important;
        &.owl-prev{
          background-image: url(../assets/images/svg/events/Left_Arrow.svg) !important;
        }
      }
    }
  }
</style>