<template>
  <div>
    <Header/>
    <template v-for="block,index in blocks" >
      <Block
              :key="index"
              :block="block"
              custom
              v-if="index < 4"
      />
      <DataCarousel :key="`${index}-pp`" v-if="index === 1 && spas.length > 0" :data="spas"/>
    </template>
    <Footer with-links/>
  </div>
</template>

<script>
    import Block from '../components/block.vue'
    import Footer from '../layout/footer'
    import DataCarousel from '../components/dataCarousel'
    import Header from '../layout/header'

    const base_url = process.env.VUE_APP_BASE_URL;
    const axios = require('axios');
    export default {
        name: "Services",
        components: {
            Block,
            Footer,
            Header,
            DataCarousel
        },
        data(){
            return{
                blocks : [],
                spas : [],
            }
        },
        watch: {
            '$locale': function(newVal) {
                this.loadData(newVal);
            }
        },
        created() {
            this.loadData(this.$locale);
            this.loadServices();
        },
        methods :{
            loadData(lang){
                axios.get(base_url + 'page/spa/'+lang).then((response) => {
                    this.blocks = response.data.data;
                }).catch((error) => {
                    console.log(error);
                });
            },
            async loadServices(){
                let res = await axios.get(base_url + 'spas');
                this.spas = res.data.spas ? res.data.spas : [];
            },
        }
    }
</script>