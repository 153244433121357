import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../pages/home'
import Events from '../pages/events'
import Rooms from '../pages/rooms'
import Golf from '../pages/golf'
import Restaurants from '../pages/restauration'
import Services from '../pages/services'
import Spa from '../pages/spa'
import TakeAir from '../pages/take_air'
import Detail from '../pages/detail'
import Preview from '../pages/preview'
import NotFound from '../pages/404'
import ConfidentialityPolitique from '../pages/politique_confidentialite'
import EnviromentalPolitiaue from '../pages/politique_environmental'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/events',
    name: 'events',
    component : Events
  },
  {
    path: '/rooms',
    name: 'rooms',
    component : Rooms
  },
  {
    path: '/golf-club',
    name: 'golf',
    component : Golf
  },
  {
    path: '/restaurants',
    name: 'restaurants',
    component : Restaurants
  },
  {
    path: '/services',
    name: 'services',
    component : Services
  },
  {
    path: '/spa',
    name: 'spa',
    component : Spa
  },
  {
    path: '/take-air',
    name: 'takeR',
    component : TakeAir
  },
  {
    path: '/rochebois',
    name: 'detail',
    component : Detail
  },
  {
    path: '/preview-page',
    name: 'preview',
    component : Preview
  },
  {
    path: '/politique-de-confidentialite',
    name: 'Confidentiality_politique',
    component : ConfidentialityPolitique
  },
  {
    path: '/politique-environnementale',
    name: 'Enviromentale_politique',
    component : EnviromentalPolitiaue
  },
  {
    path: '*',
    name: 'notFound',
    component: NotFound
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
