<template>
  <div :class="customClass">
    <div v-if="blockText" v-html="filteredHtml" class="inner-content">

    </div>
    <div class="img-ctn diagonal" v-if="withImg && imgTemplate === 'diagonal'">
        <div>
            <img :src="`http://admin.rochebois.com/${imagesData.images[0]}`" alt="">
            <img :src="`http://admin.rochebois.com/${imagesData.images[1]}`" alt="">
            <img :src="`http://admin.rochebois.com/${imagesData.images[2]}`" alt="">
        </div>
    </div>
    <div v-else-if="withImg && imgTemplate === 'single'" :class="['img-ctn',{homepage:$route.name === 'home' && popup}]" >
      <img :src="`http://admin.rochebois.com/${imagesData.images[0]}`" alt="">
      <div :class="['pop-up',{loaded:loaded},{moveIn:show},{moveOut:hide}]" v-if="popup">
        <img v-if="popUpData.image && popUpData.image.length > 0" :src="popUpData.image"  alt="">
        <span @click="toggleOpen" class="btn-close"></span>
        <div class="content">
          <span>{{$t(popUpData.titleCode)}}</span>
          <p>
            {{$t(popUpData.contentCode)}}
          </p>

          <a :href="popUpData.buttonHref" target="_blank">{{$t(popUpData.buttonCode)}}</a>
        </div>
      </div>
    </div>
    <div :class="['slides',{homepage:$route.name === 'home' && popup}]" v-else-if="withImg && imgTemplate === 'carousel'">
      <Carousel  :class="['main-carousel',{withNav : imagesData.images.length> 1}]" :dots="false"  :items="1" animation-out="fadeOut" autoplay :autoplayTimeout
              ="5000" animateOut="fadeOut" autoplayHoverPause>
        <img v-for="img,imgIdx in imagesData.images" :key="imgIdx" :src="`http://admin.rochebois.com/${img}`" />
      </Carousel>

      <div :class="['pop-up',{loaded:loaded},{moveIn:show},{moveOut:hide}]" v-if="popup">
        <img v-if="popUpData.image && popUpData.image.length > 0" :src="popUpData.image"  alt="">
        <span @click="toggleOpen" class="btn-close"></span>
        <div class="content">
          <span>{{$t(popUpData.titleCode)}}</span>
          <p>
            {{$t(popUpData.contentCode)}}
          </p>

          <a :href="popUpData.buttonHref" target="_blank">{{$t(popUpData.buttonCode)}}</a>
        </div>
      </div>
      
    </div>
    <div class="img-ctn video" v-if="video">
      <video playsinline autoplay muted loop :src="video"></video>
    </div>

  </div>
</template>

<script>
import Carousel from 'vue-owl-carousel'
export default {
  name: 'Block',
  components: {
      Carousel
  },
  props: {
    popup:Object,
    block:Object,
    custom : Boolean
  },
  data(){
      return {
          loaded:false,
          show:false,
          hide : false,
          popupClone : null
      }
  },
  /*watch: {
      'popup': function(newVal) {

      },
  },*/
  mounted(){
      if(this.popup && this.block.position === 1){
          setTimeout(()=>{
              this.loaded = true;
              this.show = true;
          },1000)
      }
  },
  computed:{
      filteredHtml: function () {
          let newHtml = this.blockText.html;
          newHtml = this.blockText.html.toString().replace('<p style="text-align: center;"><a','<a').replace('<p><a','<a').replace('</a></p>','</a>');
          return newHtml;
      },
      popUpData: function(){
          return this.popup?.popup?.data;
      },
      withImg: function(){
          return this.block?.elementImages && this.block?.elementImages?.images?.length >0;
      },
      imgTemplate: function(){
          return this.block?.elementImages?.template;
      },
      imagesData: function(){
          return this.block?.elementImages;
      },
      blockText: function(){
          return this.block?.elementText;
      },
      video: function(){
          return this.block?.elementVideo;
      },
      customClass: function(){
          return `Block  ${this.withImg ? (this.imgTemplate !== 'diagonal' ? this.imagesData.position + (this.custom ? '-img custom' : '-img') : '') : ''}`
      }
  },
  methods:{
      toggleOpen(){
          if(this.popup && this.loaded) {
              this.show = !this.show;
              this.hide = !this.hide;
          }
      }
  }
}
</script>


<style lang="scss">
.Block{
  display: flex;
  flex-direction: column;
  .inner-content{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 80px 80px 0;
      h2{
          text-align: center;
          display: block;
          text-transform: uppercase;
          color: $color2;
          letter-spacing: 5px;
          font-family: 'Engravers';
          line-height: 2;
          font-weight: 400;
          margin-bottom: 60px;
          &::after{
              content: "";
              display: block;
              width: 35%;
              height: 1px;
              background-color: $color2;
              margin: 30px auto 0;
          }
      }
      h3{
          text-align: center;
          display: block;
          text-transform: uppercase;
          color: $color2;
          letter-spacing: 3px;
          max-width: 900px;
          font-family: 'Engravers';
          font-weight: 400;
          margin-bottom: 30px;
      }
      p,h4{
          text-align: center;
          max-width: 650px;
          font-size: 1rem;
          margin-bottom: 30px;
          letter-spacing: 1px;
          font-family: 'Wremena-light';
          color: #3e464Acc;
      }
      h4{
        font-weight: 600;
      }
      > *:last-child{
          margin-bottom: 0;
      }
      a{
          display: block;
          padding: 5px 30px;
          font-size: .8rem;
          margin: 40px 0;
          min-width: 300px;
          border: 1px solid $color1;
          text-align: center;
          font-family: 'Wremena-regular';
          text-transform: uppercase;
          color: $color4;
          letter-spacing: 1px;
          transition: all .3s ease-in-out;
          &:hover{
              background-color: $color2;
              color: #ffffff;
              border-color: transparent;
          }
      }
  }
  .img-ctn{
      min-height: 80vh;
      width: 100%;
      position: relative;
      overflow: hidden;
      margin-top: 3vw;
      >img,>video{
          display: block;
          width: 100%;
          min-height: 100%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
      }
      &.diagonal {
        overflow: unset !important;
        box-shadow: rgb(0 0 0 / 25%) 1px 20px 36px -28px inset;;
        > div {
          position: relative;
          width: 60%;
          height: auto;
          margin: 200px auto;
          img{
            display: block;
            background-color: #ffffff;
            padding: 6%;
            max-width: 100%;
            box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
            &:nth-child(1),&:nth-child(3){
              position: absolute;
              width: 40%;
              padding: 4%;
            }
            &:nth-child(1){
              top: 0;
              left: 0;
              transform: translate(-20%,-70%);
            }
            &:nth-child(2){
              width: 100%;
            }
            &:nth-child(3){
              bottom: 0;
              right: 0;
              transform: translate(20%,50%);
            }
          }
        }
      }
  }
  &.left-img,&.right-img{
      flex-direction: row-reverse;
      .inner-content,.img-ctn{
          width: 50%;
          height: auto;
          img{
            height: 100%;
            width: auto;
            min-width: 100%;
          }
      }
  }
  &.right-img{
      flex-direction: row;
  }
  &.top-img{
      flex-direction: column-reverse;
  }
  .slides{
    position: relative;
    overflow: hidden;
    margin-top: 30px;
    .main-carousel{
      .owl-item{
        position: relative;
        height: 85vh;
        overflow: hidden;
        img{
          position: absolute;
          top: 50%;
          left: 50%;
          width: 100%;
          min-height: 100%;
          transform: translate(-50%,-50%);

        }
      }
      &.withNav .owl-nav{
        position: absolute;
        left: 15%;
        top: 50%;
        display: flex;
        flex-direction: column-reverse;
        transform: translateY(-50%);
        >div{
          font-size: 0 !important;
          padding: 0 !important;
          background-color: transparent !important;
          display: block !important;
          width: 4vw;
          height: 3vw;
          background-position: 50% 50% !important;
          background-size: contain !important;
          background-repeat: no-repeat !important;
          background-image: url(../assets/images/svg/Right_big.svg) !important;
          &.owl-prev{
            background-image: url(../assets/images/svg/Lefts_big.svg) !important;
          }
        }
      }
    }

  }
  &.custom{
    background-color: #fffde23b;
  }
  .pop-up{
    width: 450px;
    background-color: #ffffff;
    position: absolute;
    bottom: 20px;
    right: 20px;
    z-index: 5;
    opacity: 0;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    transform: translate(0,130%);
    transition: opacity .7s ease-in-out,transform 1.7s ease;

    &.loaded{
      transform: translate(0,0);
      opacity: 1;
    }
    &.moveIn{
      transform: translate(0,0);
      opacity: 1;
    }
    &.moveOut{
      transform: translate(130%,0);
      opacity: 0;
    }
    .btn-close {
      display: block;
      width: 30px;
      height: 30px;
      position: absolute;
      top: 0px;
      right: 0px;
      z-index: 10;
      transform: scale(.8);
      background-color: #ab974f;
      cursor: pointer;
      &::before,&::after{
        content: '';
        display: block;
        position: absolute;
        top: 14px;
        left: 3px;
        width: 80%;
        height: 2px;
        border-radius: 1px;
        background-color: #ffffff;
        opacity: .8;
      }
      &::before{
        transform: rotate(45deg);
      }
      &::after{
        transform: rotate(-45deg);
      }
    }
    img{
      width: 100%;
      height: auto;
    }
    .content{
      padding: 40px;
      span{
        display: block;
        text-transform: uppercase;
        color: #C8C48B;
        margin: 0 auto;
        width: max-content;
        text-align: center;
        font-size: 1.5rem;
        font-family: 'Engravers';
        letter-spacing: 5px;
        font-weight: 400;
        &::after{
          content: "";
          display: block;
          height: 1px;
          background-color: $color2;
          margin: 10px auto 60px;
        }
      }
      p{
        font-family: 'Wremena-light';
        font-size: .9rem;
        font-weight: 400;
        margin-bottom: 50px;
        color: #3E464A;
        text-align: center;
        padding: 0 15%;
        opacity: .8;
        max-height: 200px;
        overflow-y: auto;
      }
      a{
        display: block;
        width: 100%;
        text-align: center;
        position: relative;
        color: $color1;
        font-weight: 300;
        font-family: 'Wremena-light';
        &::after{
          content:"";
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 20px;
          height: 10px;
          background-image: url(../assets/images/svg/Black_arrow.svg);
          background-repeat: no-repeat;
          background-position: 50% 50%;
        }
      }
    }
    @media screen and (max-width: 575px){
      width: calc(100% - 40px);
      .pupup-img-ctn{
        height: 180px;
      }
      .content{
        padding: 30px 15px;
        span{
          font-size: 1rem;
          &::after{
            margin: 10px auto 20px;
          }
        }
        p{
          padding: 0;
          font-size: .7rem;
          margin-bottom: 30px;
        }
        a{
          font-size: .7rem;
          &::after{
            right: 30px;
          }
        }
      }

    }
  }

  @media screen and (max-width: 1024px){
    .inner-content{
      padding: 50px 50px 0;
      h2,h3{
        margin-bottom: 30px;
        font-size:1.2rem;
        line-height: 1.5;
        &::after{
          margin: 10px auto 0;
        }
      }
      h4,p{
        font-size: .9rem;
      }
      a{
        font-size: .7rem;
        margin: 20px 0;
      }
    }
  }
  @media screen and (max-width: 768px){
    .img-ctn{
      min-height: 40vh;

      &.diagonal > div{
        margin: 100px auto;
        img:nth-child(1),img:nth-child(3){
          width: 50%;
        }
      }
      &.homepage{
        min-height: calc(100vh - 150px);
      }
    }
    .slides .main-carousel .owl-item{
      height: 40vh;
      img{
        height: 100%;
        width: auto;
      }
    }
  }
  @media screen and (max-width: 575px){
    .inner-content{
      padding: 30px 30px 0;
      h2,h3{
        margin-bottom: 30px;
        font-size:.8rem;
        &::after{
          margin: 10px auto 0;
        }
        br{
          display: none;
        }
      }
      h4,p{
        font-size: .7rem;
      }
      a{
        font-size: .6rem;
        margin: 10px 0;
        min-width: 220px;
        padding: 5px 15px;
      }
    }
    .img-ctn {
      &.diagonal > div{
        width: 70%;
      }
      > img{
        height: 100%;
        width: auto;
      }
      &.video{
        height: auto;
        width: 100%;
        min-height: unset;
        video {
          width: 100%;
          height: auto;
          position: relative;
          transform:unset;
          top: unset;
          left: unset;
        }
      }

    }
    .slides .main-carousel{
      .owl-item{
        height: 78vh;
      }
      &.withNav .owl-nav{
        left: 2.5%;
        bottom: 0;
        width: 95%;
        top: unset;
        flex-direction: row;
        justify-content: space-between;
        div{
          width: 7vw;
          height: 5vw;
        }
      }
    }
    &.left-img,&.right-img{
      flex-direction: column;
      .inner-content,.img-ctn{
        width: 100%;
      }
      .img-ctn img{
        width: 100%;
        height: auto;
      }
    }
  }
}
</style>
