import Vue from 'vue'
import VueI18n from 'vue-i18n'
const axios = require('axios');
const base_url = process.env.VUE_APP_BASE_URL;
Vue.use(VueI18n)

async function loadLocaleMessages () {
  let translations = await axios.get(base_url + 'translations');
  let langues = await axios.get(base_url + 'languages');
  langues = langues.data.languages;
  const messages = {}
  Object.entries(translations.data.translations).map(item =>{
    let customObj = {};
    for (let i = 0; i < item[1].length; i++) {
      customObj[item[1][i].code] = item[1][i].value
    }
    messages[item[0].toLowerCase()] = customObj;
  });
  return Promise.resolve([messages,langues])
}


let i18n;


export function loadI18n(){
  return loadLocaleMessages().then(data =>{
    const initI18n = new VueI18n({
      locale: localStorage.getItem('LocaleLang') || process.env.VUE_APP_I18N_LOCALE || 'fr',
      fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'fr',
      messages: data[0]
    });
    document.documentElement.setAttribute("lang", initI18n.locale);

    Object.defineProperty(Vue.prototype, '$locale', {
      get: function () {
        return initI18n.locale
      },
      set: function (locale) {
        initI18n.locale = locale
      }
    });
    Object.defineProperty(Vue.prototype, '$langues', {
      get: function () {
        return data[1]
      },
    });
    i18n = initI18n;

    return initI18n;

  })
}

export function changeLocale(lang){
  if(i18n.locale !== lang){
    i18n.locale = lang;
    localStorage.setItem('LocaleLang',lang);
    document.documentElement.setAttribute("lang", lang);
  }

  return lang;
}
