<template>
  <div class="equipments">
    <h2>{{$t('equipementsadisposition')}} :</h2>
    <div>
    <div v-for="equip,indx in data" :key="indx">
      <img :src="equip.logo" />
      <span>{{$t(equip.text_code)}}</span>
    </div>
    <div class="last">
      <span>
        <strong>{{$t('horsforfait')}} :</strong>
        {{$t('toutautrematerielsurdemande')}}
      </span>
    </div>

    </div>
  </div>
</template>

<script>
export default {
    name: "equipements",
    props: {
        data:Array,
    },
}
</script>

<style lang="scss" scoped>
.equipments{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 80px;
  h2{
    text-align: center;
    display: block;
    text-transform: uppercase;
    color: $color2;
    letter-spacing: 5px;
    font-family: 'Hunter';
    line-height: 2;
    font-weight: 400;
    margin-bottom: 60px;

    &::after{
      content: "";
      display: block;
      width: 35%;
      height: 1px;
      background-color: $color2;
      margin: 30px auto 0;
    }
    @media screen and (max-width: 1024px){
      margin-bottom: 30px;
      font-size:1.2rem;
      line-height: 1.5;
      &::after{
        margin: 10px auto 0;
      }
    }

  }
  >div{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 75vw;
    max-width: 1024px;
    @media screen and (max-width: 1024px){
      width: 85vw;
    }
    @media screen and (max-width: 575px){
      width: 90vw;
      justify-content: space-between;
    }
    >div{
      display: flex;
      align-items: center;
      width: max-content;
      max-width: 210px;
      padding: 15px;
      margin: 0 30px 15px 0;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      @media screen and (max-width: 575px){
        max-width: 80%;
        width: max-content;
        margin: 0 auto 1rem;
      }
      img{
        display: block;
        width: 25px;
        margin-right: 15px;
      }
      span{
        font-family: 'Lucida-grande';
        color: $color4;
        letter-spacing: 1px;
        font-size: .8rem;
        flex: 1;
        text-align: center;
        &::first-letter{
          text-transform: uppercase;
        }
        @media screen and (max-width: 575px){
          text-align: left;
        }

      }
      &.last{
        background-color: transparent;
        box-shadow: unset;
        span{
          text-align: left;
          font-size: .7rem;
          strong{
            display: block;
            margin-bottom: 5px;
          }

        }
      }
    }
  }
}
</style>