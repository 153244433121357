<template>
  <cookie-law :buttonText="$t('i_understand')" >
    <div slot="message">
      {{$t('cookie_message')}}
    </div>
  </cookie-law>

</template>

<script>
import CookieLaw from 'vue-cookie-law'
export default {
  name: "cookieConsent",
  components: { CookieLaw },
  data(){
      return {
          btnText : this.$t('i_understand'),
      }
  }
}
</script>

<style lang="scss">
.Cookie__content{
  font-family: "Wremena-regular" !important;
}
.Cookie__button{
  background-color: #6e5c19 !important;
  &:hover{
    background-color: rgba(106, 94, 17, 0.57) !important;
  }
}
</style>