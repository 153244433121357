<template>
  <div>
    <Header @togglePopUp="togglePopUp"/>

    <Block
      v-for="block,blockIdx in blocks"
      :key="blockIdx"
      :block="block"
      :ref="`popBlock${blockIdx}`"
      :popup="settings"
    />

    <Footer  with-links="" with-social-media=""/>
  </div>
</template>

<script>
import Block from '../components/block.vue'
import Footer from '../layout/footer'
import Header from '../layout/header'
const base_url = process.env.VUE_APP_BASE_URL;
const axios = require('axios');
export default {
  name: 'HomePage',
  components: {
    Block,
    Footer,
    Header
  },
  data(){
    return{
        blocks : [],
        settings : null
    }
  },
  watch: {
      '$locale': function(newVal) {
          this.loadData(newVal);
      },
  },
  created() {
      this.loadData(this.$locale);
  },
  methods :{
      loadData(lang){
          axios.get(base_url + 'page/home/'+lang+'?firstCall=true').then((response) => {
              this.blocks = response.data.data;
          }).catch((error) => {
              console.log(error);
          });
          axios.get(base_url + 'setting').then((response) => {
              this.settings = response.data.settings;
          }).catch((error) => {
              console.log(error);
          });
      },
      togglePopUp(){
          this.$refs.popBlock0[0].toggleOpen();
      }
  }
}
</script>

<style lang="scss" scoped>

</style>