<template>
  <div>
    <Header />
    <template v-for="block,index in blocks" >
      <Block
              :key="index"
              :block="block"
              custom
      />
      <DataCarousel :key="`${index}-pp`" v-if="index === 1 && golf.length > 0" :data="golf" />
    </template>
    <Footer with-links/>
  </div>
</template>

<script>
import Block from '../components/block.vue'
import Footer from '../layout/footer'
import DataCarousel from '../components/dataCarousel'
import Header from '../layout/header'

const base_url = process.env.VUE_APP_BASE_URL;
const axios = require('axios');
export default {
  name: "Golf",
  components: {
      Block,
      Footer,
      Header,
      DataCarousel
  },
  data(){
      return{
          blocks : [],
          golf : [],
      }
  },
  watch: {
      '$locale': function(newVal) {
          this.loadData(newVal);
      }
  },
  created() {
      this.loadData(this.$locale);
      this.loadRooms();
  },
  methods :{
      loadData(lang){
          axios.get(base_url + 'page/golf_club/'+lang).then((response) => {
              this.blocks = response.data.data;
          }).catch((error) => {
              console.log(error);
          });
      },
      async loadRooms(){
          let res = await axios.get(base_url + 'golf-club');
          this.golf = res.data.golfClubs ? res.data.golfClubs : [];
      },
  }
}
</script>