<template>
  <header :class="{ scrolled : scrolled}">
    <ul class="site-config-left">
      <li v-if="!isMobile"><a href="tel:+33553293688" class="ft-ops-semi">+ 33 5 53 29 36 88</a></li>
      <li><a href="javascript:void(0);" @click="toggleLangList" class="ft-ops-regular">{{$t('langues')}}</a></li>
      <li><a href="javascript:void(0);" :class="['collectors',{pagehome: $route.name === 'home'}]">
        <img src="../assets/images/svg/extra_home.svg" alt="">
      </a></li>
    </ul>
    <span id="toggleMenu" :class="{clicked : btnClicked}" @click="btnClicked = !btnClicked">
      <i></i>
      <i></i>
      <i></i>
    </span>
    <ul class="site-config-right">
      <li >
        <a v-if="$route.name === 'home'" href="javascript:void(0);" @click="$emit('togglePopUp')" class="ft-ops-bold">{{$t('actualite')}}</a>

      </li>
      <li v-if="!isMobile" :class="[{homepage:$route.name !== 'home'}]"><a href="https://rochebois.career.softgarden.de/" class="ft-ops-regular" target="_blank">{{$t('recrutement')}}</a></li>
      <li class="bookNowBtn"><a :href="reservationLink" target="_blank" class="ft-ops-regular">{{$t('reserver')}}</a></li>
      <li style="display: none;"><a href="tel:+33553293688" class="ft-ops-semi">+ 33 5 53 29 36 88</a></li>
    </ul>


    <router-link class="logo" to="/">
      <img src="../assets/images/svg/Logo.svg" alt="">
    </router-link>
    <nav v-if="showNav">

      <ul>
        <li>
            <router-link to="/">{{$t('le_domaine')}}</router-link>
        </li>
        <li>
          <router-link to="/rooms">{{$t('chambreetsuite')}}</router-link>
        </li>
        <li>
          <router-link to="/restaurants">{{$t('restaurantion')}}</router-link>
        </li>
        <li>
          <router-link to="/spa">{{$t('spaparnuxe')}}</router-link>
        </li>
        <li>
            <router-link to="/take-air">{{$t('prenezair')}}</router-link>
        </li>
        <li>
          <router-link to="/services">{{$t('services')}}</router-link>
        </li>
        <li>
          <router-link to="golf-club">{{$t('golfclub')}}</router-link>
        </li>
        <li>
          <router-link to="events">{{$t('evenements')}}</router-link>
        </li>
        <li>
            <a href="https://domainederochebois.bonkdo.com/" target="_blank">{{$t('offres')}}</a>
        </li>
        <li>
            <a href="javascript:void(0);">{{$t('actualite')}}</a>
        </li>
        <li>
            <a href="https://rochebois.career.softgarden.de/" target="_blank">{{$t('recrutement')}}</a>
        </li>
        <li>
            <a href="tel:+33553293688">+ 33 5 53 29 36 88</a>
        </li>
        <li>
            <a href="javascript:void(0);">{{$t('langues')}}</a>
        </li>
      </ul>
    </nav>
    <div :class="['side-nav',{show : btnClicked}]">
      <a href="javascript:void(0);" @click="btnClicked = !btnClicked"></a>
      <ul>
        <li>
          <router-link @click.native="btnClicked = !btnClicked" to="/">{{$t('le_domaine')}}</router-link>
        </li>
        <li>
          <router-link @click.native="btnClicked = !btnClicked" to="/rooms">{{$t('chambreetsuite')}}</router-link>
        </li>
        <li>
          <router-link @click.native="btnClicked = !btnClicked" to="/restaurants">{{$t('restaurantion')}}</router-link>
        </li>
        <li>
          <router-link @click.native="btnClicked = !btnClicked" to="/spa">{{$t('spaparnuxe')}}</router-link>
        </li>
        <li>
          <router-link @click.native="btnClicked = !btnClicked" to="/take-air">{{$t('prenezair')}}</router-link>
        </li>
        <li>
          <router-link @click.native="btnClicked = !btnClicked" to="/services">{{$t('services')}}</router-link>
        </li>
        <li>
          <router-link @click.native="btnClicked = !btnClicked" to="golf-club">{{$t('golfclub')}}</router-link>
        </li>
        <li>
          <router-link @click.native="btnClicked = !btnClicked" to="events">{{$t('evenements')}}</router-link>
        </li>
        <li>
          <a href="https://domainederochebois.bonkdo.com/" target="_blank">{{$t('offres')}}</a>
        </li>
        <template v-if="isMobile">
          <li>
            <a href="https://rochebois.career.softgarden.de/" target="_blank">{{$t('recrutement')}}</a>
          </li>
          <li>
            <a href="tel:+33553293688">+ 33 5 53 29 36 88</a>
          </li>
        </template>
      </ul>
    </div>

    <div :class="['lang-list',{show: showLangList}]">
      <a href="javascript:void(0);" @click="toggleLangList"></a>
      <ul>
        <template v-for="lang,key in $langues" >
          <li :key="key" v-if="lang.status === 1" :class="[{active : $locale === lang.code.toLowerCase()}]" @click="changeLang(lang.code.toLowerCase())">{{lang.label}}</li>
        </template>

        <!--<li @click="changeLang('en')">english</li>
        <li @click="changeLang('de')">german</li>
        <li @click="changeLang('es')">spanish</li>-->
      </ul>
    </div>

    <div class="backdrop" v-if="btnClicked || showLangList"></div>
  </header>
</template>

<script>
import {changeLocale} from '../i18n'
export default {
  name: 'Header',
  props: {
  },
  data : ()=>{
    return{
      scrolled : false,
      showNav : false,
      btnClicked : false,
      showLangList:false
    }
  },
  mounted(){
      this.scrolled = window.pageYOffset > 150;
      this.showNav = window.pageYOffset < 150;
    window.addEventListener('scroll',()=>{
        this.scrolled = window.pageYOffset > 150;
    })
  },
  computed:{
    isMobile: function(){
        return window.innerWidth<= 575;
    },
    getDates(){
      const months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10',	'11', '12'];
      let today = new Date();
      let tomorrow = new Date(today);
      tomorrow.setDate(tomorrow.getDate() + 1);
      return {
          today: today.getFullYear() + '-' + months[today.getMonth()] + '-' + (today.getDate().toString().length > 1 ? today.getDate() : '0' +today.getDate()),
          tomorrow: tomorrow.getFullYear() + '-' + months[tomorrow.getMonth()] + '-' + (tomorrow.getDate().toString().length > 1 ? tomorrow.getDate() : '0' +tomorrow.getDate())
      }
    },
    reservationLink(){
        return `https://thebookingbutton.site/${this.$locale}/availibility/Domaine-de-Rochebois.html?adults=2&currency=EUR&idetab=14997&city=18#/checkprice/availibility`;
      //return `https://thebookingbutton.site/${this.$locale}/availibility/Domaine-de-Rochebois.html?adults=2&currency=EUR&start=${this.getDates.today}&end=${this.getDates.tomorrow}&idetab=14997&city=18#/checkprice/availibility`
    }
  },
  methods : {
    changeLang : function (lang){
      changeLocale(lang);
      this.toggleLangList();
    },
    toggleLangList: function () {
        this.showLangList = !this.showLangList;
    }
  }
}
</script>

<style lang="scss">
header{
  display: flex;
  flex-direction: column;
  align-items: center;
  position: sticky;
  top: 0px;
  left: 0px;
  height: 190px;
  width: 100%;
  z-index: 100;
  background-color: #ffffff;
  color: $color1;
  transition: box-shadow .7s linear;
  @media screen and (max-width: 900px){
    height: 150px;
  }
  &.animate{
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      nav{
          opacity: 1 !important;
      }
  }
  &.scrolled{
     box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    nav{
      transform: translateY(0);
      opacity: 0;
    }
    #toggleMenu{
      display: block;
    }
    .site-config-left .collectors{
      opacity: 0;
      z-index: -100;
    }
  }
  >ul{
      position: absolute;
      top: 30px;
      display: flex;
      align-items: center;
      list-style: none;
      li{
          padding: 0 15px;
          a{
              text-transform: uppercase;
              font-size: .8rem;
              letter-spacing: 3px;
              opacity: .8;
            @media screen and (max-width: 1024px){
              font-size: .7rem;
            }
            @media screen and (max-width: 575px){
              font-weight: 700 !important;
            }

          }
        @media screen and (max-width: 900px){
          &.bookNowBtn {
            padding: 0 !important;
            a{
              position: fixed;
              bottom: 0;
              left: 0;
              z-index: 5;
              opacity: 1 !important;
              padding: 15px;
              width: 100%;
              background-color: $color5;
              text-align: center;
              color: #ffffff !important;
              font-size: 1rem;
            }
          }
        }

      }
      &.site-config-left{
          left: 20px;
          top: 20px;
        @media screen and (max-width: 900px){
          left: 10px;
        }
          li {
              border-left: 1px solid $color1;
              &:nth-child(1),&:last-child{
                  border-color: transparent;
              }
              &:nth-child(2){
                  a{
                      opacity: .3;
                  }
              }
            &:last-child{
              a{
                opacity: 1;
              }
            }

              a{
                  color: $color1;
              }
          }
      }
      &.site-config-right{
        right: 20px;
        @media screen and (max-width: 1024px){
          top: 20px;
        }
        @media screen and (max-width: 900px){
          right: 10px;
        }
        li {
          border-left: 1px solid $color1;
          &:nth-child(1){
              border-color: transparent;
          }
          &:nth-child(2){
              a{
                  opacity: .4;
              }
          }
          &:nth-child(3){
              border-color: transparent;
              a{
                  display: block;
                  padding: 12px 20px;
                  color: $color4;
                  border: 2px solid $color5;
                  opacity: 1;
              }
          }
          &.homepage{
            border-color: transparent;
          }
          a{
              color: $color1;
          }
          @media screen and (max-width: 575px){
            padding: 0;
          }
        }

      }
  }
  .logo{
    display: block;
    width: 200px;
    margin: 50px 0 20px 0;
    img{
        width:100%;
        display: block;
    }
    @media screen and (max-width: 1024px){
      width: 150px;
    }
    @media screen and (max-width: 900px){
      margin: 20px 0 0px 0;
    }
  }
  #toggleMenu{
    display: none;
    position: absolute;
    top: 90px;
    left: 40px;
    width: 30px;
    height: 30px;
    z-index: 1;
    opacity: 0;
    cursor: pointer;
    animation: animate-btn .3s ease forwards;
    animation-delay: .2s;
    i{
      display: block;
      width: 100%;
      height: 3px;
      background-color: $color4;
      margin-bottom: 6px;
      &:nth-child(2){
        width: 66%;
      }
      &:nth-child(3){
        width: 33%;
      }
    }
    @keyframes animate-btn {
      0%{
        transform: translateX(-20%) scale(.8);
        opacity: 0;
      }
      100%{
        transform: translateX(0%) scale(1);
        opacity: .8;
      }
    }
    @media screen and (max-width: 1200px){
      display: block !important;
    }
    @media screen and (max-width: 900px){
      top: 75px;
      left: 25px;
    }
  }
  nav{
    width: 100%;
    opacity: 100;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    transform: translateY(100%);
    transition: all .3s ease-in;
    background-color: #ffffff;
    ul{
      display: flex;
      width: 90%;
      margin: 0 auto;
      justify-content: space-between;
      align-items: center;
      list-style:none;
      li{
        padding: 15px 5px;
        text-align: center;
        &:nth-child(10),&:nth-child(11),&:nth-child(12),&:nth-child(13){
            display: none;
        }
        a{
          text-transform: uppercase;
          font-size: .7rem;
          font-family: 'Hunter';
          opacity: .7;
          color: $color4;
          letter-spacing: 3px;
          transition: all .3s ease-in-out;
          &:hover,&.router-link-exact-active{
            opacity: 1;
            font-weight: 700;
          }
        }
      }
    }
    @media screen and (max-width: 1440px){
      ul{
        width: 98%;
      }
    }
    @media screen and (max-width: 1200px){
      display: none;
    }
  }
  .side-nav{
    padding: 200px 80px 80px;
    width: 40vw;
    height: 100vh;
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    opacity: 0;
    transform: translateX(-150%);
    background-color: #ffffff;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    transition: all .4s ease-in-out;
    @media screen and (max-width: 900px){
      width: 80vw;
    }
    @media screen and (max-width: 575px){
      width: 100vw;
      padding: 80px 20px 20px;
    }
    &.show{
      transform: translateX(0);
      opacity: 1;
      >a{
        opacity: 1;
        transform: scale(1);
      }
      &~.backdrop{
        opacity: 1;
        z-index: 5;
      }
    }
    >a{
      display: block;
      width: 30px;
      height: 30px;
      position: absolute;
      top: 10px;
      right: 10px;
      z-index: 10;
      opacity: 0;
      transition: all .25s ease-in-out;
      transform: scale(.8);
      transition-delay: .3s;
      &::before,&::after{
        content: '';
        display: block;
        position: absolute;
        top: 14px;
        left: 0;
        width: 100%;
        height: 2px;
        border-radius: 1px;
        background-color: $color4;
        opacity: .8;
      }
      &::before{
        transform: rotate(45deg);
      }
      &::after{
        transform: rotate(-45deg);
      }
    }
    ul{
      display: flex;
      flex-direction: column;
      width: 100%;
      margin: 0 auto;
      justify-content: space-between;
      align-items: center;
      list-style:none;
      li{
        padding: 15px 5px;
        text-align: center;
        a{
          text-transform: uppercase;
          font-size: 1rem;
          font-family: 'Hunter';
          opacity: .5;
          color: $color4;
          letter-spacing: 3px;
          transition: all .3s ease-in-out;
          &:hover,&.router-link-exact-active{
            opacity: 1;
            font-weight: 600;
          }
        }
      }
    }
  }
  .lang-list{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 20;
    width: 40vw;
    height: 100vh;
    display: flex;
    align-items: center;
    background-color: #ffffff;
    opacity: 0;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    transform: translateX(-130%);
    transition: all .3s ease-in-out;
    @media screen and (max-width: 900px){
      width: 80vw;
    }
    @media screen and (max-width: 575px){
      width: 100vw;
    }
    &.show{
      opacity: 1;
      transform: translateX(0);
      a{
        opacity: 1;
        transform: scale(1);
      }
      &~.backdrop{
        opacity: 1;
        z-index: 5;
      }
    }
    a{
      display: block;
      width: 30px;
      height: 30px;
      position: absolute;
      top: 10px;
      right: 10px;
      z-index: 10;
      opacity: 0;
      transition: all .25s ease-in-out;
      transform: scale(.8);
      transition-delay: .3s;
      &::before,&::after{
        content: '';
        display: block;
        position: absolute;
        top: 14px;
        left: 0;
        width: 100%;
        height: 2px;
        border-radius: 1px;
        background-color: $color4;
        opacity: .8;
      }
      &::before{
         transform: rotate(45deg);
      }
      &::after{
        transform: rotate(-45deg);
      }
    }
    ul{
      display: flex;
      flex-direction: column;
      padding: 40px 0;
      width: 100%;
      li{
        min-width: 350px;
        text-align: center;
        padding: 20px 20px;
        color: $color4;
        cursor: pointer;
        font-family: 'Hunter';
        letter-spacing: 2px;
        font-size: 1rem;
        opacity: .7;
        text-transform: uppercase;
        transition: all .3s ease-in-out;
        &.active,&:hover{
          font-weight: 700;
          opacity: 1;
        }
      }
    }
  }
  .collectors{
    display: block;
    z-index: 10;
    width: 80px;
    transition: all .4s ease-in-out;
    img{
      display: block;
      width: 100%;
    }
    @media screen and (max-width: 1024px){
      display: none;
    }
    @media screen and (max-width: 575px){
      display: block;
      opacity: 1 !important;
      z-index: 1 !important;
      position: fixed;
      top: 20px;
      right: 20px;
      width: 50px;
      &.pagehome{
        top: 60px;
      }
    }
  }
  .backdrop{
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: -10;
    opacity: 0;
    background-color: rgba(0,0,0,.4);
  }
}
</style>