import Vue from 'vue'
import App from './App.vue'
import './assets/style/style.scss'
import router from './router'
import {loadI18n} from './i18n'
Vue.config.productionTip = false;

Vue.prototype.$testGlobal = 'teeeest';

loadI18n().then(function(i18n){
  new Vue({
    router,
    i18n,
    render: h => h(App)
  }).$mount('#app')
})


