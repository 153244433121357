<template>
    <footer>
        <div class="first" v-if="withSocialMedia">
          <span>#{{$t('domainederochebois').replace(' ','').replace(' ','')}}</span>
          <Carousel class="footer-carousel" :dots="false"  :nav="false" :autoWidth="true">
            <div class="img-ctn">
              <img src="../assets/images/webp/Social_1.webp">
            </div>
            <div class="img-ctn">
              <img src="../assets/images/webp/Social_3.webp">
            </div>
            <div class="img-ctn">
              <img src="../assets/images/webp/Social_4.webp">
            </div>
            <div class="img-ctn">
              <img src="../assets/images/webp/Social_5.webp">
            </div>
             <div class="img-ctn">
              <img src="../assets/images/webp/Social_1.webp">
            </div>
            <div class="img-ctn">
              <img src="../assets/images/webp/Social_3.webp">
            </div>
            <div class="img-ctn">
              <img src="../assets/images/webp/Social_4.webp">
            </div>
            <div class="img-ctn">
              <img src="../assets/images/webp/Social_5.webp">
            </div>
            <div class="img-ctn">
              <img src="../assets/images/webp/Social_6.webp">
            </div>
            <div class="img-ctn">
              <img src="../assets/images/webp/Social_7.webp">
            </div>
            <div class="img-ctn">
              <img src="../assets/images/webp/Social_8.webp">
            </div>

        </Carousel>
        <div class="social-media">
          <a href="https://facebook.com/domainederochebois/" target="_blank" class="facebook">facebook</a>
          <a href="https://www.instagram.com/domainederochebois/" target="_blank" class="instagram">instagram</a>
          <!--<a href="javascript:void(0)" class="twitter">twitter</a>-->
        </div>
        </div>
        <div class="second" v-if="withLinks">

            <div class="logo-form">
              <a href="/" class="logo">
                <img src="../assets/images/svg/Logo_footer.svg" alt="">
              </a>
              <ContactForm />
            </div>
            <div class="inner-second">
              <div>
                <span>{{$t('le_domaine')}}</span>
                <router-link to="home">{{$t('accueil')}}</router-link>
                <a href="javascript:void(0);">{{$t('exterieuretjardins')}}</a>
                <a href="javascript:void(0);">{{$t('clubhouse')}}</a>
                <router-link to="golf-club">{{$t('golf')}}</router-link>
              </div>
              <div>
                <span>{{$t('leschambres')}}</span>
                <router-link to="rooms">{{$t('chambresconfortterrasse')}}</router-link>
                <router-link to="rooms">{{$t('chambredeluxe')}}</router-link>
                <router-link to="rooms">{{$t('chambrepriivilege')}}</router-link>
                <router-link to="rooms">{{$t('chambreprestige')}}</router-link>
                <router-link to="rooms">{{$t('chambrejuniorsuite')}}</router-link>
              </div>
              <div>
                <span>{{$t('lessuites')}}</span>
                <router-link to="rooms">{{$t('suite')}}</router-link>
                <router-link to="rooms">{{$t('suiteprestige')}}</router-link>
                <router-link to="rooms">{{$t('suiteduplex')}}</router-link>
              </div>
              <div>
                <span>{{$t('restaurantsetbars')}}</span>
                <router-link to="restaurants">{{$t('lemderochebois')}}</router-link>
                <router-link to="restaurants">{{$t('lewedge')}}</router-link>
                <router-link to="restaurants">{{$t('barinterieur')}}</router-link>
                <router-link to="restaurants">{{$t('barexterieur')}}</router-link>

              </div>
              <div>
                <span>{{$t('services')}}</span>
                <router-link to="services">{{$t('receptionh24')}}</router-link>
                <router-link to="services">{{$t('serviceenchambres')}}</router-link>
                <router-link to="services">{{$t('blanchisserie')}}</router-link>
                <router-link to="services">{{$t('voiturier')}}</router-link>
                <router-link to="services">{{$t('autresservices')}}</router-link>
              </div>
              <div>
                <span>{{$t('spa')}}</span>
                <router-link to="spa">{{$t('shop')}}</router-link>
                <router-link to="spa">{{$t('services')}}</router-link>
                <router-link to="spa">{{$t('soins')}}</router-link>
                <router-link to="spa">{{$t('bain')}}</router-link>
              </div>
              <div>
                <span>{{$t('offres')}}</span>
                <a href="javascript:void(0);">{{$t('prenezrderochebois')}}</a>
                <a href="javascript:void(0);">{{$t('allinr')}}</a>
                <a href="javascript:void(0);">{{$t('packagegolfspa')}}</a>
                <a href="javascript:void(0);">{{$t('loveisinther')}}</a>
                <a href="javascript:void(0);">{{$t('spahealthyfood')}}</a>
              </div>
              <div>
                <span>{{$t('evenements')}}</span>
                <router-link to="events">{{$t('charitybusiness')}}</router-link>
                <router-link to="events">{{$t('concert')}}</router-link>
              </div>
            </div>
        </div>
        <div class="third">
          <span>{{$t('domainederochebois')}} ©</span>
          <span>{{$t('rochebois_adress')}}</span>
          <ul>
            <router-link to="/politique-de-confidentialite">{{$t('privacyandcookies')}}</router-link>
            <li>{{$t('termsandconditions')}}</li>
            <router-link to="/politique-environnementale">{{$t('politiquesenvironmontale')}}</router-link>
          </ul>
        </div>
      <CookieConsent />
    </footer>
</template>

<script>
import Carousel from 'vue-owl-carousel'
import ContactForm from "../components/contact";
import CookieConsent from '../components/cookieConsent'
export default {
  name: 'Footer',
  components:{
      ContactForm,
      CookieConsent,
      Carousel
  },
  props: {
      withSocialMedia: Boolean,
      withLinks : Boolean
  },
  data : ()=>{
    return{
      
    }
  },
}
</script>

<style lang="scss">
footer{
    .first{
      padding: 2rem 0vw 2rem 5vw;
      @media screen and (max-width: 575px){
        padding: 1rem 0vw 1rem 1vw;
      }
      span{
        display: block;
        text-transform: uppercase;
        color: $color2;
        letter-spacing: 3px;
        padding-left: .8rem;
        font-family: 'Engravers';
      }

      .footer-carousel{
          .owl-item{
            .img-ctn{
            
            width: 220px;
            height: 220px;
            position: relative;
            overflow: hidden;
            margin: 1.5rem .8rem;
            transition: box-shadow .4s ease-in-out;
            @media screen and (max-width: 768px){
              width: 150px;
              height: 150px;
            }
            &:hover{
              box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
            }
            img{
              position: absolute;
              top: 50%;
              left: 50%;
              width: auto;
              height: auto;
              transform: translate(-50%,-50%);
              min-width: 100%;
              min-height: 100%;
            }
          }
        }
        
      }
      .social-media{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        a{
          padding: 5px 40px;
          display: block;
          position: relative;
          font-family: 'Wremena-regular';
          letter-spacing: 1px;
          color: $color2;
          font-size: .9rem;
          &::first-letter{
            text-transform: uppercase;
          }
          &::after{
            content: "";
            display: block;
            width: 15px;
            height: 15px;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            background-position: 50% 50%;
            background-repeat: no-repeat;

          }
          &.facebook::after{
            background-image: url(../assets/images/svg/facebook.svg);
          }
          &.instagram::after{
            background-image: url(../assets/images/svg/instagram.svg);
          }
          &.twitter::after{
            background-image: url(../assets/images/svg/instagram.svg);
          }
        }
      }
    }
    .second{
      background-color: #484848; // $color2;
      padding: 80px 50px;


      .logo-form{
        display: flex;
        justify-content: space-around;
        align-items: flex-start;
        margin-bottom: 3rem;
        @media screen and (max-width: 575px){
          flex-direction: column;
        }
        .logo{
          display: block;
          width: 200px;
          img{
            display: block;
            width: 100%;
            height: auto;
          }
        }
      }
      .inner-second{
        display: flex;
        justify-content: space-between;
        @media screen and (max-width: 1440px){
          flex-wrap: wrap;
        }
        >div{
          display: flex;
          flex-direction: column;

          color: #ffffff;
          @media screen and (max-width: 1440px){
            width: 25%;
            margin-bottom: 1rem;
          }
          @media screen and (max-width: 1024px){
            width: 33.33%;
          }
          @media screen and (max-width: 575px){
            width: 50%;
          }
          span{
            text-transform: uppercase;
            opacity: .6;
            display: block;
            letter-spacing: 3px;
            margin-bottom: 15px;
            font-family: 'Engravers';
          }
          a{
            display: block;
            color: inherit;
            letter-spacing: 1px;
            margin-bottom: 8px;
            font-family: "Wremena-regular";
            line-height: 1;
            &::first-letter{
              text-transform: uppercase;
            }
            &:last-child{
              margin-bottom: 0;
            }
          }
        }
      }
      @media screen and (max-width: 575px){
        padding: 50px 20px;
        .logo{
          width: 150px;
          margin: 0 auto 40px;
        }
        a{
          font-size: .8rem !important;
        }

      }
    }
    .third{
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 30px;
      background-color: #484848; // $color2;
      font-family: "Wremena-light";
      border-top: 1px solid #ffffff;
      @media screen and (max-width: 900px){
        margin-bottom: 50px;
      }
      @media screen and (max-width: 1440px){
        flex-wrap: wrap;
      }
      @media screen and (max-width: 575px){
        padding: 15px;
      }
      span{
        color: #ffffff;
        font-family: inherit;
        font-size: 1rem;
      }
      *::first-letter{
        text-transform: uppercase;
      }
      ul{
        display: flex;
        list-style: none;
        @media screen and (max-width: 1440px){
          width: 100%;
          margin-top: 1rem;
          justify-content: space-between;

        }


        li,a{
          font-size: .8rem;
          color: #ffffff;
          padding-right: 50px;
          cursor: pointer;
          &:last-child{
            padding: 0;
          }

        }

      }
      @media screen and (max-width: 575px){
        ul{
          flex-direction: column;
          margin-top: 2rem;
          li,a{
            font-size: 1rem;
            padding: 0;
            margin-bottom: .5rem;

          }
        }
        span{
          margin-bottom: 1rem;
        }
        *{
          text-align: center;
          width: 100%;
        }

      }
    }
}
</style>