<template>
  <div class="salles">
    <div v-for="salle,index in data" :key="index" class="salle">
      <img :src="`http://admin.rochebois.com/${salle.image}`" alt="">
      <span>{{$t(salle.title)}}</span>
      <div class="ctn">
        <div v-for="el,index2 in salle.elements" :key="index2">
          <span>{{$t('nomdusalon')}} :</span>
          <span>{{$t(el.name).toUpperCase()}}</span>
          <span>{{$t('superficie')}}: {{el.area}}m</span>
          <span>{{$t('theatre')}}: {{el.theater}}</span>
          <span>{{$t('ecolier')}}: {{el.schoolboy}}</span>
          <span>{{$t('table_u')}}: {{el.table_u}}</span>
          <span>{{$t('cocktail')}}: {{el.cocktail === 0 ? '-' : el.cocktail}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: "Salles",
    props: {
        data:Array,
    },
}
</script>

<style lang="scss" scoped>
  .salles{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 1000px;
    margin: 0 auto;
    @media screen and (max-width: 1024px){
      max-width: 750px;
    }
    @media screen and (max-width: 768px){
      max-width: 90vw;
    }
    >div{
      width: 47%;
      padding: 60px;
      margin: 0 auto;
      margin-bottom: 2rem;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      @media screen and (max-width: 1024px){
        padding: 30px 15px;
      }
      @media screen and (max-width: 575px){
        width: 100%;
      }
      img{
        height: 100px;
        width: auto;
        margin: 0 auto 2rem;
        display: block;
      }
      >span{
        display: block;
        font-family: 'Hunter';
        text-align: center;
        font-size: 1.6rem;
        color: $color5;
        text-transform: uppercase;
        letter-spacing: 3px;
        margin-bottom: 1rem;
        @media screen and (max-width: 1024px){
          font-size: 1.3rem;
        }
        @media screen and (max-width: 575px){
          font-size: 1.1rem;
        }
      }
      .ctn{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;

        > div{
          width: 50%;
          margin-bottom: 1rem;
          &:nth-child(2n){
            border-left: 1px solid $color2;
          }
          @media screen and (max-width: 575px){
            //width: 100%;
          }
          *{
            display: block;
            font-family: 'Opensans-light';
            color: $color1;
            text-align: center;
            font-size: 1rem;
            &:first-letter{
              text-transform: uppercase;
            }
            @media screen and (max-width: 768px){
              font-size: .85rem;
            }
          }
        }
      }
    }
  }
</style>