<template>
  <Carousel class="data-carousel" v-if="autoWidth" autoHeight autoWidth :dots="false" :margin="margin" >
    <div class="item" v-for="item,index in data" :key="index">
      <img :src="item.image" v-if="item.image.length > 300" :alt="$t(item.title_code)">
      <img :src="`http://admin.rochebois.com/${item.image}`" v-else :alt="$t(item.title_code)">
      <span :class="[{golden : gold ? true : false}]">{{$t(item.title_code)}}</span>
      <p>{{$t(item.details_code)}}</p>
      <router-link v-if="item.page && item.page.length >0" :to="{ name: 'detail', query: { p: item.page }}">{{$t('ensavoirplus')}}</router-link>
    </div>
  </Carousel>
  <Carousel v-else class="data-carousel" :items="1" autoHeight  :dots="false" :margin="margin" >
    <div class="item" v-for="item,index in data" :key="index">
      <img :src="item.image" v-if="item.image.length > 300" :alt="$t(item.title_code)">
      <img :src="`http://admin.rochebois.com/${item.image}`" v-else :alt="$t(item.title_code)">
      <span :class="[{golden : gold ? true : false}]">{{$t(item.title_code)}}</span>
      <p>{{$t(item.details_code)}}</p>
      <router-link v-if="item.page && item.page.length >0" :to="{ name: 'detail', query: { p: item.page }}">{{$t('ensavoirplus')}}</router-link>
    </div>
  </Carousel>
</template>

<script>
import Carousel from 'vue-owl-carousel'
export default {
  name: "dataCarousel",
  components: {
      Carousel
  },
  props:{
      data : Array,
      gold : Boolean,
  },
  data(){
    return {
        autoWidth: window.innerWidth > 575
    }
  },
  computed:{
      margin : function(){
          switch (true) {
              case window.innerWidth <= 1024:
                  return 50;
              default:
                  return 100;
          }
      },
      items: function(){
          if(window.innerWidth > 575){
              return {};
          }else{
              return {
                  items: 1
              }
          }
      }
  }
}
</script>

<style lang="scss">
  .data-carousel {
    padding-left: 10vw;
    //margin: 10vw 0;
    position: relative;
    @media screen and (max-width: 575px){
      padding: 0 15px;
      margin: 10vw 0 15vw 0;
    }
    .item{
      width: 30vw;
      @media screen and (max-width: 1024px){
        width: 40vw;
      }
      @media screen and (max-width: 768px){
        width: 55vw;
      }
      @media screen and (max-width: 575px){
        width: 100%;
      }
      img{
        width: 100%;
        height: auto;
      }
      span{
        display: block;
        margin: 40px 0;
        color: $color6;
        font-family: 'Engravers';
        font-size: 1.5rem;
        text-transform: uppercase;
        letter-spacing: 4px;
        text-align: center;
        &.golden{
          color: $color2;
        }
        @media screen and (max-width: 1024px){
          margin: 30px 0;
        }
        @media screen and (max-width: 575px){
          font-size: 1.2rem;
          margin: 20px 0;
        }
      }
      p{
        text-align: center;
        font-family: 'Wremena-regular';
        padding: 0 80px;
        font-size: .9rem;
        color: #3e464Acc;
        line-height: 1.7;
        letter-spacing: 1px;
        &::first-letter{
          text-transform: uppercase;
        }
        @media screen and (max-width: 1024px){
          padding: 0px 40px;
        }
        @media screen and (max-width: 575px){
          padding: 0px 10px;
          font-size: .8rem;
        }
      }
      >a{
        display: flex;
        align-items: center;
        width: max-content;
        justify-content: center;
        position: relative;
        color: $color1;
        font-weight: 300;
        margin: 40px auto 0;
        font-family: 'Wremena-light';
        &::after{
          content:"";
          display: inline-block;
          margin-left: 20px;
          width: 20px;
          height: 10px;
          background-image: url(../assets/images/svg/Black_arrow.svg);
          background-repeat: no-repeat;
          background-position: 50% 50%;
        }
      }
    }
    .owl-nav{
      position: absolute;
      left: 0;
      top: 30%;
      display: flex !important;
      flex-direction: column-reverse;
      transform: translate(-200%,-50%);
      >div{
        font-size: 0 !important;
        padding: 0 !important;
        background-color: transparent !important;
        display: block !important;
        width: 3vw;
        height: 2vw;
        background-position: 50% 50% !important;
        background-size: contain !important;
        background-repeat: no-repeat !important;
        background-image: url(../assets/images/svg/events/Right_Arrow.svg) !important;
        &.owl-prev{
          background-image: url(../assets/images/svg/events/Left_Arrow.svg) !important;
        }
      }
      @media screen and (max-width: 575px){
        width: 100%;
        left: 0;
        top: unset;
        bottom: 0;
        transform: translateY(120%);
        flex-direction: row;
        justify-content: space-between;
        div{
          width: 7vw;
          height: 5vw;
        }
      }
    }
  }
</style>