<template>
  <div>
    <Header />
  <img src="../assets/images/svg/404.svg" alt="">
  </div>
</template>

<script>
import Header from '../layout/header'
export default {
    name: "NotFound",
    components:{Header}
}
</script>

<style scoped>
img{
  display: block;
  width: 50%;
  margin: 50px auto 200px;
}
</style>